.title {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #101010;
  width: 230px;
}

.action_wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  margin-top: 16px
}

.action_wrapper button {
  width: 30%;
}

.info_wrapper {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center
}
