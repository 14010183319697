.mainWrapper {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;
}

.backgroundDots {
  position: absolute;
  width: 500px;
  top: 20px;
}

@media screen and (max-width: 500px) {
  .backgroundDots {
    position: absolute;
    width: 300px;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 32px;
  text-align: center;
  font-weight: 700;
  color: #29323d;
}

.logoTitle {
  font-size: 32px;
  text-align: center;
  font-weight: 400;
  color: #29323d;
  margin-top: 0;
  margin-bottom: 50px;
}

.titleUser {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #101010;
}

.titleCompany {
  color: #707070;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

.companyWrapper {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 8px 0 81px 0;
}

.company {
  display: flex;
}

.logo {
  width: 60px;
  height: 56px;
}

.description {
  color: #353535;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin-top: 16px;
  margin-bottom: 0;
}
