
.bottomInfoWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    gap: 4px;
    max-width: 1200px;
    margin-top: auto;
}

.actionsWrapper {
    display: flex;
    gap: 30px;
}

.bottomInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 5px;
}

.bottomInfoText {
    color: #707070;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 11px;
    font-weight: 300;
    white-space: nowrap;
    text-transform: capitalize;
}

.phoneCallWrapper {
    display: flex;
    gap: 8px;
}

.callerPhone {
    color: #353535;
    display: flex;
    align-items: center;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: 400;
}
