.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
}

.contentWrapper {
  position: relative;
  max-width: 100vw;
  //padding: 0 16px;
  box-sizing: border-box;
  margin: 0 auto;
}

header {
  //position: fixed;
  //top: 0;
  //left: 0;
  width: 100%;
  padding: 8px;
  background: #FFFFFF;
  z-index: 10;
  border-bottom: 0.2px solid #ABABAB;
  max-width: 1200px
}

.headerContent {
  max-width: 1240px;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.headerIcon {
  &:hover {
    cursor: pointer;
  }
}

.infoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
  //height: 13px;
  //padding: 9px 0;
}

.logoWrapper {
  margin-left: 3px;
  color: #353535;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
}

.infoContent {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #b7b7b7;
}

.logo {
  width: 100px;
  height: 100px;
  left: 10px;
  top: 0;
  cursor: pointer;
}

.menu {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.logoApp {
  width: 109px;
  height: 30px;
  cursor: pointer;
}

.microphone {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.menuWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 10px;
  top: 10px;
}

.btnLog {
  width: 80px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  padding: 5px;
  color: #1f2335;
  margin-top: 5px;
  cursor: pointer;
  background-color: #f4f7ff;
  border-radius: 3px;
  border: 1px solid #1f2335;
}

.main {
  width: 100%;
  //padding-top: 45px;
  background: #ffffff;
}

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  height: calc(100vh - 47px);
}

.footer {
  background: #ffffff;
  width: 100%;
  border-top: 0.5px solid #ABABAB;
}

.footerLogoWrapper {
  display: flex;
  align-items: center;
  gap: 13px;
}

.footerLogo {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: lightgray;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
}


.logoStatus {
  position: absolute;
  right: 0;
  bottom: 0;
}

.username {
  color: #353535;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.userEmail {
  color: #8E8E8E;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.logoutWrapper {
  display: flex;

  &:hover {
    cursor: pointer;
  }
}

.footerContent {
  padding: 16px 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px
}
