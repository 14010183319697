.callerWrapper {
  z-index: 1;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 27px 28px 0 28px;

  //box-shadow: 0 18.5px 14px -4px rgba(0, 0, 0, 0.3);
}

.line {
  padding-bottom: 16px;
  border-bottom: 0.5px solid #ABABAB;

}

.callerInfo {
  display: flex;
  width: 100%;
  justify-content: space-between;
  //border-bottom: 1px solid #e7e7e7;
  //margin-bottom: 14px;
  padding-bottom: 27px;
}

.callerPhone {
  font-weight: 400;
  font-size: 16px;
  color: #101010;
}

.callerName {
  font-weight: 700;
  font-size: 16px;
  color: #101010;
  margin: 5px 0;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.infoTitle {
  color: #707070;
  font-family: Poppins, sans-serif;
  font-size: 11px;;
  font-weight: 300;
  line-height: 16px;
  text-transform: capitalize;
}

.infoText {
  color: #353535;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.infoTextFilled {
  padding: 3px 8px;
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), rgba(243, 208, 119, 0.70);
}

.infoButton {
  border-radius: 9px;
  border: 1px solid var(--primary-purpel, #3E1656);
  background-color: white;

  color: #3E1656;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  height: max-content;
  padding: 8px 16px;
  transition: all ease-in-out 0.25s;

  &:hover {
    background-color: #3E1656;
    color: white;
    transition: all ease-in-out 0.25s;
  }
}

.callerId {
  font-weight: 400;
  font-size: 16px;
  text-decoration-line: underline;
  color: #101010;
}

.timerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 8px;
  }
}

.signatureWrapper {
  display: flex;
  align-items: center;
}

.signatureData {
  font-weight: 400;
  font-size: 12px;
  color: #101010;
  opacity: 0.7;
  margin-left: 13px;
}

.callerUpdate {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.styled {
  padding: 8px 10px;
  font-weight: 400;
  font-size: 12px;
  max-width: 90px;
  height: max-content;
  color: #101010;
  background-color: #ffffff;
  border: 1px solid #bbbbbb;

  img {
    margin-right: 7px;
  }
}

.lineWrapper {
  position: relative;
  display: flex;
  width: max-content;
}

.lineTwo {
  animation: play 2s 1s ease-in-out infinite;
}

.lineOne {
  animation: play 2s 2s ease-in-out infinite;
}

@keyframes play {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, 0.5);
  }

  100% {
    transform: scale(1, 1);
  }
}
