.resultWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  //background-color: #bc0000;
  width: 100%;
  height: 100%;
  padding: 6px 0 37px 0;
  justify-content: center;
}

.speechIdentify {
  font-weight: 400;
  font-size: 20px;
  color: #FFFFFF;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 4px;
}

.isSuccess {
  background-color: #18830f;
  color: #72bb79;
}

.isEnroll {
  background-color: #ffffff;
  color: #72bb79;
}

.descriptionText {
  color: #353535;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 300;
}

.errorTitle {
  color: #EA424A;
  font-family: "Maven Pro", sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-transform: capitalize;
}

.statusIcon {
  margin-top: 43px;
}

.isSuccessIcon {
  margin-left: 20px;
}

.isEnrollIcon {
  width: 98px;
  height: 123px;
}

.statusText {
  font-family: "Maven Pro", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: capitalize;
  margin-top: -22px;
}

.isEnrollText{
  font-size: 24px;
  color: #101010;
}

.homeScreenBtn {
  width: 250px;
  margin-top: 50px;
}
